export default {
  "common": {
    "optional": "optional",
    "back_btn": {
      "to_profile": "Back to profile page"
    },
    "read_more": "Read more",
    "option_toggle_global": "Global",
    "loading": "Loading ..."
  },
  "one": "One",
  "two": "Two",
  "three": "Three",
  "four": "Four",
  "five": "Five",
  "file_reader_error": "Sorry, FileReader API is not supported.",
  "form_errors": "Please fill in all fields in the form to continue",
  "form_success": "Profile details have been stored successfully.",
  "close": "Close",
  "onboarding": {
    "step": "Step",
    "of": "of",
    "progress_title": "Become a mentor",
    "progress_description": "Complete the form in the steps below to become a VC4A mentor.",
    "title_step_1": "Bio data",
    "title_step_2": "Mentor experience details",
    "title_step_3": "Connections details",
    "save_and_continue": "Next",
    "submit_for_approval": "Submit for approval",
    "email_hint": "You will receive updates on this email address. You can ",
    "email_hint_link": "change it here",
    "about_hint": "Please explain why you are interested in mentoring and describe what your interests and focus areas are.",
    "about_modal": {
      "title": "About - Example",
      "content": "Hello, my name is Jack and I’ve been building a social media company and a payments solution over the past 15 years. I’m interested in Africa and Latin America as I believe these are the most exciting startup spaces on the continent right now. I’m looking to support early-stage startup founders in their set-up phase as I believe mentors can add the most value then. My interests are fintech, social media platforms, and anything related to Web3."
    },
    "upload_new_picture": "Upload new picture",
    "upload_new_picture_in_progress": "Uploading...",
    "remove_picture": "Remove picture",
    "crop_avatar": "Crop avatar",
    "save_changes": "Save changes",
    "mentor_type": {
      "label": "What type of mentor are you?"
    },
    "spoken_languages": {
      "label": "Spoken language(s)",
      "hint": "This is the languages you fluently speak and write for mentorship engagements."
    },
    "sectors": {
      "label": "Select sectors",
      "hint": "These are sectors where you have gained experience during your career."
    },
    "geo_focus": {
      "label": "Select geographical focus",
      "hint": "These are countries/regions you are interested in mentoring entrepreneurs, either because you are from there or you have worked or traveled there."
    },
    "topics": {
      "label": "Select topics",
      "hint": "These are the topics you will be mentoring in."
    },
    "mentorship_stage": {
      "label": "What stage will you want to offer mentorship to?",
      "hint": "We advice that only experienced mentors should select growth or mature stage."
    },
    "number_of_calls": {
      "title": "Communication",
      "label": "Number of calls",
      "hint": "This is the number of times you want to speak with the entrepreneur during the duration of the connection request. "
    },
    "number_of_calls_alert": {
      "title": "Messaging",
      "message": "Unlimited chat, e-mail or text within boundaries between entrepreneur and mentors, is standard for all mentor connections."
    },
    "mentor_motivation": {
      "label": "Mentorship motivation",
      "hint": "Why do you mentor?",
      "explain": "Please explain why",
      "why": "Mentorship motivation explainer"
    },
    "explainer": {
      "stages": {
        "title": "Startup stages explained",
        "idea": {
          "title": "Idea/Concept stage",
          "explainer": "This is the ideation stage, where the founder(s) do(es) research around the ‘Problem/Solution Fit’ and working towards the Minimum Viable Product (MVP) to test the response in the market. Mentorship will most likely focus on how to turn an idea from a concept on paper into a reality."
        },
        "startup": {
          "title": "Startup stage",
          "explainer": "Having launched the Minimum Viable Product (MVP) this is the stage to test the Product/Market Fit and re-iterate based on the response from the market. Mentorship will most likely focus on team composition, feedback from the market, adjusting the business model and so on."
        },
        "growth": {
          "title": "Growth stage",
          "explainer": "Having found Product/Market fit it’s now time to scale up the operations and the team to implement. Mentorship will most likely focus on the growth-pains every company is going through when it comes to expanding the business."
        },
        "mature": {
          "title": "Mature stage",
          "explainer": "Maturity means growth rates are slowing down, but with internationalization comes building localized teams to adapt the product to the intricacies and cultural nuances of each new region. During this stage exit opportunities can also be part of the mentorship discussions."
        }
      },
      "type": {
        "title": "Mentor types explained"
      }
    },
    "gender": {
      "label": "Gender",
      "option_male": "Male",
      "option_female": "Female",
      "option_other": "Other",
    }
  },
  "profile": {
    "edit_profile": "Edit profile",
    "pending_alert": {
      "title": "Awaiting approval",
      "message": "Your mentor profile has been successfully created and submitted for approval."
    },
    "approved_alert": {
      "title": "Profile approved",
      "message": "Congratulations, your mentor profile has been approved."
    },
    "mentorship_connection": "Mentorship connection",
    "month": "month",
    "includes": "Includes",
    "benefits": {
      "messages": "Unlimited chat, email, or text within boundaries.",
      "checklist": "Structured connection checklist to aid tracking.",
      "support": "Support from VC4A admins."
    },
    "view_requests": "View requests"
  },
  "edit_profile": {
    "page_title": "Edit your mentor profile",
    "page_subtitle": "Below you can make changes to your mentor profile. To edit your VC4A user profile,",
    "click_here": "click here",
    "mod_comments" : {
      "title": "Moderator's Comments",
      "content": "If you’ve updated the changes above, you can submit your profile for review with the button below.",
      "re_review_cta": "Re-submit for review",
      "success_title": "Success!",
      "success_message": "Profile changes successfully submitted for review.",
      "hint": "Your request has been submitted for review. You may still edit your request while it’s pending review.",
      "review_action_activity": "{moderator} requested for changes",
      "card_title": "Please note"
    },
    "navigation": {
      "bio": "Bio",
      "experience": "Experience",
      "connections": "Connections"
    },
    "save_changes": "Save changes"
  },
  "mentor_filter": {
    "panel": {
      "title": "All Mentors",
      "subtitle": "Use the terms below to further streamline your search.",
      "toggle_btn": "Filter by",
      "sort_by": "Sort by"
    },
    "labels": {
      "name": "Name",
      "venture_type": "Type",
      "stage": "Venture stage",
      "language": "Spoken language(s)",
      "sector": "Sector",
      "country": "Based in",
      "focus_locations": "Interested in",
      "topics": "Topics"
    },
    "placeholders": {
      "name": "Search by name...",
      "focus_locations": "Select region or country..."
    }
  },
  "mentor_request": {
    "page_title": "Request mentorship from",
    "page_subtitle": "Complete the steps below to apply for mentorship.",
    "title_step_1": "Requirements",
    "title_step_2": "Request details",
    "title_step_3": "Introduction to Mentor",
    "requirement_1": "Sign in or create a VC4A account to continue.",
    "requirement_1_success": "You're signed in",
    "requirement_2": "You need a venture profile to request for mentorship.",
    "requirement_3": "Have you just created your venture profile? Refresh this page to continue.",
    "select_venture": "Select a venture and make sure the profile is up to date and contains a logo",
    "mod_comments": {
      "alert": {
        "success": {
          "title": "Success!",
          "message": "Mentor request changes successfully submitted for review."
        }
      },
      "cta_explanation": "If you’ve updated the changes above, you can re-submit your request for review with the button below."
    },
    "mod_controls": {
      "title": "Moderator controls",
      "btn_approve": "Approve",
      "btn_change": "Require changes",
      "btn_deny": "Deny",
      "btn_ban": "Ban",
      "confirm_delete": {
        "text_end": "This cannot be undone",
        "button": "Delete permanently",
        "mentee": {
          "title": "Delete Mentee's account permanently?",
          "text": "Are you sure you want to delete the following Mentee's account?"
        },
        "mentor_request": {
          "title": "Delete Mentor request permanently?",
          "text": "Are you sure you want to delete the following Mentor request?"
        }
      }
    },
    "statuses": {
      "pending": "Pending",
      "active": "Active",
      "closed": "Closed",
      "review": "Pending review",
      "moderation": "Changes required",
      "rejected": "Rejected",
      "denied": "Denied",
    },
    "alts": {
      "venture_avatar": "Venture Avatar",
      "avatar": "Mentee Avatar"
    },
    "topic": {
      "label": "Which topics from the list below do you want to discuss with the mentor?",
      "hint": "Select a minimum of 1 and a maximum of 3 topics."
    },
    "request_title_placeholder": "Looking for mentorship to improve business model",
    "request_title_label": "Request title",
    "intro_to_mentor": {
      "label": "Introduce yourself to the mentor",
      "hint": "Tell about yourself and explain to the mentor why you believe they have the experience/expertise you are looking for."
    },
    "request_card": {
      "label": "From",
      "cta": "View request"
    },
    "problem": {
      "label": "Problem",
      "hint": "Explain the challenge you are facing. What steps have you already taken to try and address this challenge."
    },
    "request": {
      "label": "Request",
      "hint": "Explain what you want to discuss with this mentor and what your expectations are for the mentorship period."
    },
    "ventures_not_allowed": "Ventures below do not match mentor requirements.",
    "nav_menu": {
      "agreement": "Agreement",
      "info": "Request info",
      "feed": "Discussions"
    }
  },
  "status_modal":{
    "success": {
      "mentor_profile": {
        "title": "Congratulations",
        "heading": "Your mentor profile has been created successfully!",
        "content": "Please note that your profile will pass through a vetting and moderation process before it is public and then you can start receiving requests from mentees.",
        "button": "View my profile"
      },
      "mentor_request": {
        "title": "Congratulations",
        "heading": "Your mentor request has been created successfully!",
        "content": "Please note that your mentor request will pass through a vetting and moderation process before it is shared with the mentor.",
        "button": "View my mentor request"
      },
      "mentor_request_update": {
        "heading": "Your mentor request has been updated successfully!"
      }
    }
  },
  "select_placeholder": "-- select --",
  "select_placeholder_multiple": "-- select --",
  "full_name": "Full name",
  "email_address": "Email address",
  "city": "City",
  "country": "Country",
  "about": "About",
  "linkedin_profile": "LinkedIn Profile",
  "see_example": "See example",
  "submit": "Submit",
  "settings": "Settings",
  "sector": "Sector",
  "focus_country": "Focus country",
  "topic": "Topic",
  "interest": "Interest",
  "venture_stage": "Venture stage",
  "options_selected": "options selected",
  "avatar": "Avatar",
  "spoken_language": "Spoken language",
  "geo_focus": "Geographical focus",
  "mentor_type": "Mentor type",
  "venture_type": "Venture stage",
  "get_started": "Get started",
  "vc4a_account": "VC4A account",
  "venture_profile": "Venture profile",
  "add_venture": "Add venture",
  "other": "Other",
  "back": "Back",
  "venture": "Venture",
  "mentor": "Mentor",
  "entrepreneur": "Entrepreneur",
  "you": "You",
  "validation": {
    "required": "is required.",
    "url": "is not a valid URL",
    "invalid": "is invalid",
    "integer": "should be an integer",
    "between": "should be a value between",
    "too_short": "Too short.",
    "min_length": "field should contain at least",
    "characters": "characters",
    "default_name": "This field"
  },
  "error_message": {
    "city": "City field is mandatory",
    "country": "Country field is mandatory",
    "linkedin_profile": "LinkedIn profile field is mandatory",
    "about": "About field is mandatory",
    "spoken_languages": "Spoken Languages field is mandatory",
    "mentor_type": "Mentor type field is mandatory",
    "sectors": "Sectors field is mandatory",
    "topic": "Topic field is mandatory",
    "mentor_motivation": {
      "why": "Mentor motivation field is mandatory."
    },
    "mentor_request": {
      "alert_title": "Oops. Your venture profile is not yet complete.",
      "sign_in": "Sign in or create a VC4A account to continue.",
      "no_venture_profile": "You need a venture profile to request for mentorship.",
      "select_venture_profile": "You need to select a venture to continue.",
      "your_venture": "As the mentor will visit the venture profile for",
      "venture_req": "please take time to complete it as much as possible. As a minimum please upload your company logo to continue.",
      "update_venture": "Update your venture"
    }
  },
  "single_request": {
    "back_btn": {
      "mentee": "Back to mentor page",
      "mentor": "Back to requests"
    },
    "alert": {
      "pending": {
        "title": "Pending review",
        "message": "Your request has been submitted for review. You may still edit your request while it’s pending review."
      },
      "approved": {
        "title": "Approved",
        "message": "Your request has been approved by VC4A, and is now awaiting a response from the mentor."
      },
      "accepted": {
        "title": "Accepted",
        "message": "Your request has been accepted by the mentor."
      }
    },
    "request_content": "Request content",
    "introduction": "Introduction",
    "problem": "Problem",
    "request": "Request",
    "topics": "Topics",
    "linked_venture": "Linked venture",
    "view_venture_profile": "View venture profile",
    "view_account": "View account",
    "view_mentee_profile": "View entrepreneur profile",
    "view_mentor_profile": "View mentor profile",
    "from": "From",
    "to": "To",
    "action_panel_hint": "Only you can see this",
    "modal": {
      "accept_request": {
        "action": "Accept request",
        "confirmation": "Are you sure you want to accept the request"
      },
      "reject_request": {
        "action": "Decline request",
        "confirmation": "Are you sure you want to decline the request from {menteeName}?",
        "reason_legend": "Provide feedback for the mentee",
        "reason_text_placeholder": "Enter your reason here",
        "text_note": "This information will be shared with the mentee",
        "no_time": "I don’t have time at the moment",
        "no_expertise": "This isn’t within my expertise",
        "not_clear": "The mentorship request isn’t clear",
        "other": "Other reason (please specify)"
      },
      "close_request": {
        "action": "Close request",
        "confirmation": "Are you sure you want to close the request?"
      },
      "edit_request": {
        "action": "Edit request"
      },
      "private_note": {
        "action": "Add a private note",
        "placeholder": "Write here...",
        "button_text": "Save note"
      },
      "report": {
        "action": "Report",
        "confirmation": "Help us understand the problem. What is going on with this request?"
      }
    },
    "reason": "Reason",
    "close_request": {
      "title": "Close this request?",
      "action": "Yes, close request"
    },
    "mentorship_info": {
      "title": "Mentorship information"
    },
    "page_title": "Mentorship connection",
    "page_hint": "Between {mentor} (Mentor) and {mentee} (Mentee).",
    "manage_mentorship_btn": "Manage mentorship",
    "help_btn": "View help",
    "discussions": "Discussions",
    "help_modal": {
      "title": "Guidelines for your mentorship",
      "learn_more_btn_text": "Learn more about mentorship connections"
    },
    "agreement_help_modal": {
      "title": "Guidelines for your mentorship agreement",
      "learn_more_btn_text": "Learn more about mentorship agreements"
    },
    "program": {
      "title": "Program",
      "cta_text": "View program page"
    }
  },
  "status": {
    "status": "Status",
    "moderation": "Changes required",
    "pending_review": "Pending Review",
    "pending_acceptance": "Pending Review",
    "approved": "Active",
    "accepted": "Accepted",
    "rejected": "Declined",
    "active": "Active",
    "inactive": "Inactive",
    "review": "Pending Review",
    "publish": "Active",
    "closed": "Closed",
    "pending_inactive": "Closed",
    "unlisted": "Published (unlisted)",
    "unavailable": "Unavailable",
    "private": "Hidden",
    "denied": "Denied",
    "completed": "Completed",
    "draft_description": "Your request is in draft",
    "moderation_description": "Your request has been reviewed and requires changes.",
    "pending_review_description": "Your request is pending review.",
    "pending_acceptance_description": "Your request is pending review.",
    "pending_inactive_description": "Your request has been closed because of inactivity.",
    "inactive_description": "Your request has been closed because of inactivity.",
    "approved_description": "This mentorship connection is active.",
    "rejected_description": "Your request has been declined.",
    "closed_description": "This request has been closed.",
    "completed_description": "This request has been closed."
  },
  "from": "from",
  "to": "to",
  "of": "of",
  "mentorship_feed": {
    "title": "Your mentorship feed",
    "progress": "Progress",
    "items_done": "items done",
    "show_more_activity": "Load more",
    "no_activity": {
      "part_1": "Start a conversation.",
      "part_2": "Kick off the mentorship by proposing a date / time and communication link/number to meet for the first time."
    },
    "comment_button": "Leave a comment"
  },
  "mentor_request_index": {
    "page_title": "Mentorship requests to you",
    "page_subtitle": "Here you will find requests that have been sent to you.",
    "navigation": {
      "pending": "Pending",
      "active": "Active",
      "history": "History"
    },
    "empty_results": "You don't have any requests",
    "request_count": "You have {count} mentor request | You have {count} mentor requests",
    "request_card": {
      "request_date": "Request date",
      "cta_text": "View mentorship",
      "mentee_hint": "Mentee",
      "venture_profile_hint": "Linked venture profile"
    },
    "see_more_btn_text": "See more requests"
  },
  "manage_profile": {
    "title": "Manage your profile",
    "hint": "Only you can see this.",
    "moderation_comment": "Comment(s) from VC4A team",
    "resubmit_text": "Please make the required adjustments via the Edit Profile button below and resubmit your profile by saving your changes.",
    "status_description": {
      "publish": "Your profile has been approved by the team and is now listed.",
      "moderation": "The VC4A team has feedback on your profile, check the comment(s) above and edit your profile.",
      "review": "Your profile is currently awaiting review from the moderator.",
      "unlisted": "Your profile is published and active, but currently not publicly listed.",
      "unavailable": "You have set your profile to be unavailable, you can change availability via the settings page.",
      "private": "Your profile has been set to private and is not visible to other users."
    },
    "moderation_title" : "Moderate",
    "moderation_approve" : "Approve",
    "moderation_approve_unlisted" : "Approve (unlisted)",
    "moderation_changes" : "Require changes",
    "moderation_deny" : "Deny",
    "moderation_ban" : "Ban",
    "requests_title": "Requests",
    "pending_requests_unit": "{count} pending request | {count} pending requests",
    "matched_requests_unit": "{ count } active connection | {count} active connections",
    "pending_requests_cta": "You have pending requests. Click to view them.",
    "moderation_comment_title": "Moderation comments",
    "moderation_comment_placeholder": "Enter requested changes here...",
    "moderation_deny_reason_placeholder": "Optional: deny reason..."
  },
  "actions": "Actions",
  "profile_settings": {
    "page_subtitle": "Here are the settings for your mentor’s profile.",
    "mentorship_settings": "Mentorship settings",
    "availability_label": "Are you available?",
    "max_connections_label": "Maximum connection limit:",
    "email_notifications": "Email notifications",
    "update_notifications": "Update notifications preferences",
    "allow_connection_label": "ONLY allow requests from entrepreneurs that:",
    "match_selected_ventures": "Match my selected venture stage(s)",
    "match_selected_sectors": "Match my selected sector(s)",
    "match_focus_locations": "Match my selected focus location(s)",
    "available" : "I am currently available to take connections",
    "unlisted_available" : "I am available to take connections from anyone on the Mentorship Marketplace (your profile will be reviewed by the moderators)",
    "unlisted" : "I am currently only available for specific program(s)",
    "unavailable" : "I am currently unavailable",
    "private" : "I am currently unavailable and hide my profile",
    "delete_profile_title": "Delete mentor profile",
    "delete_profile_desc": "Do you want to delete your mentor profile? This is a permanent action and cannot be reversed.",
    "delete_profile_cta": "Delete your mentor profile",
    "delete_profile_modal": {
      "alert_message": "If you don’t read this, unexpected bad things will happen.",
      "confirmation_text_1": "Are you sure you want to delete your VC4A mentor profile",
      "confirmation_text_2": "This is a permanent action and cannot be undone. This action deletes:",
      "list_item_1": "all profile information",
      "list_item_2": "all requests attached to your profile",
      "password_label": "Please enter your password to confirm",
      "cta": "Delete this mentor profile",
    },
    "delete_profile_success": "Your mentor profile has been deleted successfully.",
    "max_connections_placeholder": "Number between 1 and 10"
  },
  "sort": {
    "date_asc": "Date (oldest first)",
    "date_desc": "Date (newest first)",
    "name_asc": "Name (A-Z)",
    "name_desc": "Name (Z-A)"
  },
  "in": "In",
  "role": "Role",
  "organization": "Organization",
  "feedback": "Feedback",
  "review": "Review",
  "close_request": {
    "page_title": "Close request",
    "feedback_question": "How did the mentorship go?",
    "write_a_review": "Write a review",
    "rate": "Rate",
    "use_review": "Allow us to use this review on the mentor’s profile.",
    "as_mentor": {
      "review_hint": "Rate your experience of your mentee in this request."
    },
    "as_mentee": {
      "review_hint": "Rate your experience of your mentor in this request."
    }
  },
  "star_rating": {
    "default": "Tap a star to rate",
    "1": "Poor",
    "2": "Fair",
    "3": "Good",
    "4": "Very good",
    "5": "Excellent"
  },
  "rating": "Rating",
  "dismiss": "Dismiss",
  "activity": {
    "title": "Activity"
  },
  "contact_form": {
    "title": "Contact us",
    "description": "Fill out the form below and a VC4A representative will contact you as soon as possible.",
    "send_message": "Send message",
    "success_message": "Message sent successfully",
    "message_label": "Message"
  },
  "message": "Message",
  "mentorship_agreement": {
    "page_title": "Mentorship agreement",
    "start_date": "Mentorship start date",
    "duration": "Duration of mentorship",
    "frequency": "Frequency of meeting",
    "frequency_meta": "{count} per {unit}",
    "challenges": "Challenges",
    "challenges_identified": "Challenges identified",
    "closed_agreement_hint": "No agreement found for this mentorship connection.",
    "add_challenge": "Add a new challenge",
    "goals": "Goals",
    "mentorship_goals": "Goals for mentorship",
    "add_goal": "Add a new goal",
    "submit_btn": "Save agreement",
    "tab_title": "Agreement",
    "agreement_hint": "This is a requirement for both the mentee and mentor. It helps both parties set a defined contract.",
    "create_agreement": "Create a mentorship agreement",
    "create_agreement_btn": "Create agreement",
    "help_btn": "What is this?",
    "note_title": "Note",
    "edit_btn_text": "Edit agreement",
    "back_btn_text": "Back to Mentor Request"
  },
  "meeting_frequency": {
    "1": "One meeting",
    "2": "Two meetings",
    "3": "Three meetings",
    "4": "Four meetings",
    "5": "Five meetings",
    "6": "Six meetings",
    "7": "Seven meetings",
    "8": "Eight meetings",
    "9": "Nine meetings"
  },
  "mentee": "Mentee",
  "moderator": "Moderator",
  "connection_details": {
    "title": "Details",
    "request_date": "Request date"
  },
  "select_all": "Select all",
  "meta": {
    "deleted_user_name": "deleted user",
  },
}
