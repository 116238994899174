export default {
  "common": {
    "optional": "opcional",
    "back_btn": {
      "to_profile": "Volver a la página del perfil"
    },
    "read_more": "Lee más",
    "option_toggle_global": "Internacional",
    "loading": "Cargando ..."
  },
  "one": "Un",
  "two": "Dos",
  "three": "Tres",
  "four": "Cuatro",
  "five": "Cinco",
  "file_reader_error": "Lo sentimos, la API de FileReader no es compatible.",
  "form_errors": "Por favor, rellene todos los campos del formulario para continuar",
  "form_success": "Los detalles del perfil se han almacenado correctamente.",
  "close": "Cerrar",
  "onboarding": {
    "step": "Paso",
    "of": "de",
    "progress_title": "Convertirse en un mentor",
    "progress_description": "Complete el formulario en los siguientes pasos para convertirse en un mentor de vc4a.",
    "title_step_1": "Datos personales",
    "title_step_2": "Detalles de experiencia del mentor",
    "title_step_3": "Detalles de su perfil como mentor",
    "save_and_continue": "Siguiente",
    "submit_for_approval": "Enviar solicitud",
    "email_hint": "Recibirás actualizaciones en esta dirección de correo electrónico. Puedes ",
    "email_hint_link": "cambiarla aquí",
    "about_hint": "Breve descripción de tu perfil.",
    "about_modal": {
      "title": "À propos - Exemple",
      "content": "Hola, mi nombre es Jack y he estado construyendo una empresa de redes sociales y una solución de pagos durante los últimos 15 años. Estoy interesado en África y América Latina, ya que creo que estos son los espacios de inicio más emocionantes del continente en este momento. Busco apoyar a los fundadores de startups en etapa inicial en su fase de configuración, ya que creo que los mentores pueden agregar el mayor valor en ese momento. Mis intereses son fintech, plataformas de redes sociales y todo lo relacionado con Web3."
    },
    "upload_new_picture": "Sube una foto de perfil",
    "upload_new_picture_in_progress": "Subiendo...",
    "remove_picture": "Cómo quitar imagen",
    "crop_avatar": "Avatar de cultivo",
    "save_changes": "Guardar cambios",
    "mentor_type": {
      "label": "¿Qué tipo de mentor eres?"
    },
    "spoken_languages": {
      "label": "Idiomas",
      "hint": "Estos son los idiomas que habla y escribe con fluidez."
    },
    "sectors": {
      "label": "Seleccionar sectores",
      "hint": "Seleccione un mínimo de 1 sector y un máximo de 5 sectores."
    },
    "geo_focus": {
      "label": "Seleccionar interés geográfico",
      "hint": "Estos son los países/regiones donde te interesa ofrecer mentorías a emprendedores, ya sea porque es el lugar donde naciste, trabajaste o viajaste en algún momento."
    },
    "topics": {
      "label": "Seleccionar temáticas",
      "hint": "Seleccione un mínimo de 1 tema y un máximo de 5 temas."
    },
    "mentorship_stage": {
      "label": "¿En qué etapa quiere brindar mentoría?",
      "hint": "Aconsejamos que sólo mentores experimentados seleccionen las etapas de Crecimiento o Madura."
    },
    "number_of_calls": {
      "title": "Comunicación",
      "label": "Cantidad de sesiones",
      "hint": "Este es el número de veces que desea reunirse con el/la emprendedor/a durante la duración de la mentoría."
    },
    "number_of_calls_alert": {
      "title": "Mensajería",
      "message": "Es de esperarse que la comunicación por chat, correo o texto sea ilimitada, dentro de los límites establecidos por el/la emprendedor/a y el/la mentora."
    },
    "mentor_motivation": {
      "label": "Motivación de la mentoría",
      "hint": "¿Por qué quieres ser mentor?",
      "explain": "Por favor, explique el por qué.",
      "why": "Explicación de la motivación de la mentoría."
    },
    "explainer": {
      "stages": {
        "title": "Detalles de cada etapa",
        "idea": {
          "title": "Etapa idea/concepto",
          "explainer": "Esta es la etapa de ideación, donde el/los fundador/es hace/n la investigación en torno al 'Problema/Solución' y trabaja/n hacia el Producto Mínimo Viable (MVP) para probar la respuesta del mercado. La mentoría se centrará muy probablemente en cómo transformar la idea de un concepto en papel en una realidad."
        },
        "startup": {
          "title": "Etapa emergente",
          "explainer": "Después de haber lanzado el Producto Mínimo Viable (MVP) esta es la etapa para probar el ajuste del Producto/Mercado y volver a iterar en función de la respuesta del mercado. Es muy probable que la mentoría se centre en la composición del equipo, la retroalimentación del mercado, el ajuste del modelo de negocio y así sucesivamente."
        },
        "growth": {
          "title": "Etapa de crecimiento",
          "explainer": "Luego de lanzar el producto/mercado y es aceptado por el mercado, ahora es el momento de escalar las operaciones y el equipo. La mentoría se centrará muy probablemente en los “dolores” de crecimiento que cada empresa está atravesando cuando se trata de expandir el negocio."
        },
        "mature": {
          "title": "Etapa de madurez",
          "explainer": "La madurez significa que las tasas de crecimiento se están desacelerando, pero con la internacionalización viene la construcción de equipos localizados para adaptar el producto a las complejidades y los matices culturales de cada nueva región. Durante esta etapa las oportunidades de salida también pueden ser parte de las discusiones de la mentoría."
        }
      },
      "type": {
        "title": "Explicación de los tipos de mentores"
      }
    },
    "gender": {
      "label": "Sexo",
      "option_male": "Hombre",
      "option_female": "Mujer",
      "option_other": "Otros",
    }
  },
  "profile": {
    "edit_profile": "Editar perfil",
    "pending_alert": {
      "title": "Pendiente de aprobación",
      "message": "El perfil de mentores VC4A se ha creado satisfactoriamente y se ha enviado para su aprobación."
    },
    "approved_alert": {
      "title": "Perfil aprobado",
      "message": "Felicidades, su perfil de mentor ha sido aprobado."
    },
    "mentorship_connection": "Conexión de mentoría",
    "month": "mes",
    "includes": "Incluye:",
    "benefits": {
      "messages": "Chat ilimitado, correo electrónico o texto dentro de los límites.",
      "checklist": "Lista de comprobación de conexión estructurada para realizar el seguimiento.",
      "support": "Soporte de los administradores de VC4A."
    },
    "view_requests": "Ver solicitudes"
  },
  "edit_profile": {
    "page_title": "Editar el perfil de su mentor",
    "page_subtitle": "Está realizando cambios en el contenido del perfil de su mentor. Para editar su perfil vc4a principal,",
    "click_here": "haga clic aquí",
    "navigation": {
      "bio": "Bio",
      "experience": "Experiencia",
      "connections": "Conexiones"
    },
    "save_changes": "Guardar cambios",
    "mod_comments": {
      "title": "Comentarios del moderador",
      "content": "Si ha actualizado los cambios anteriores, puede enviar su perfil para su revisión con el botón de abajo.",
      "re_review_cta": "Vuelva a presentarla para su revisión",
      "success_title": "¡Éxito!",
      "success_message": "Los cambios de perfil se han sometido satisfactoriamente para revisión.",
      "hint": "Tu solicitud ha sido enviada para revisión. Todavía puedes editar tu solicitud mientras está pendiente de revisión.",
      "review_action_activity": "{moderador} solicitado cambios",
      "card_title": "Tenga en cuenta"
    }
  },
  "mentor_filter": {
    "panel": {
      "title": "Todos los mentores",
      "subtitle": "Utilice los siguientes términos para agilizar su búsqueda.",
      "toggle_btn": "Filtrar por",
      "sort_by": "Ordenar por"
    },
    "labels": {
      "name": "Nombre",
      "venture_type": "Tipo",
      "stage": "Etapa de la empresa",
      "language": "Idioma(s) hablado(s)",
      "sector": "Sector",
      "country": "Basada en",
      "focus_locations": "Interesado en",
      "topics": "Temas"
    },
    "placeholders": {
      "name": "Buscar por nombre...",
      "focus_locations": "Seleccione región o país..."
    }
  },
  "mentor_request": {
    "page_title": "Solicitar mentoría de",
    "page_subtitle": "Complete los pasos a continuación para solicitar la mentoría.",
    "title_step_1": "Requisitos",
    "title_step_2": "Pedir detalles",
    "title_step_3": "Introducción al mentor",
    "requirement_1": "Inicie sesión o cree una cuenta VC4A para continuar.",
    "requirement_1_success": "Has iniciado sesión",
    "requirement_2": "Necesita un perfil de empresa para solicitar una mentoría.",
    "requirement_3": "¿Ha creado su perfil de empresa? Actualice esta página para continuar.",
    "select_venture": "Seleccione una empresa y asegúrese de que el perfil está actualizado y contiene un logotipo",
    "mod_comments": {
      "alert": {
        "success": {
          "title": "¡Éxito!",
          "message": "Cambios en la solicitud de los mentores presentados con éxito para su revisión."
        }
      },
      "cta_explanation": "Si ha actualizado los cambios anteriores, puede volver a enviar su solicitud de revisión con el botón de abajo"
    },
    "mod_controls": {
      "title": "Controles del moderador",
      "btn_approve": "Aprobar",
      "btn_change": "Requerir cambios",
      "btn_deny": "Denegar",
      "btn_ban": "Prohibición",
      "confirm_delete": {
        "text_end": "Esto no se puede deshacer",
        "button": "Borrar permanentemente",
        "mentee": {
          "title": "¿Eliminar la cuenta de Mentee de forma permanente?",
          "text": "¿Estás seguro de que quieres eliminar la siguiente cuenta de Mentee?"
        },
        "mentor_request": {
          "title": "¿Borrar permanentemente la solicitud de Mentor?",
          "text": "¿Estás seguro de que quieres eliminar la siguiente solicitud de Mentor?"
        }
      }
    },
    "statuses": {
      "pending": "Pendiente",
      "active": "Activo",
      "review": "Revisión pendiente",
      "closed": "Cerrado",
      "moderation": "Modificaciones necesarias",
      "rejected": "Rechazado",
      "denied": "Denegado",
    },
    "alts": {
      "venture_avatar": "Avatar de la Empresa",
      "avatar": "Avatar del Alumno"
    },
    "topic": {
      "label": "¿Qué temas quieres preguntarle al mentor?",
      "hint": "Seleccione un mínimo de 1 y un máximo de 3 temas."
    },
    "request_title_label": "Título del mensaje",
    "intro_to_mentor": {
      "label": "Introducción al mentor",
      "hint": "Preséntate y explicale al mentor por qué crees que tiene la experiencia que buscas."
    },
    "request_card": {
      "label": "Hecho por",
      "cta": "Ver solicitud"
    },
    "problem": {
      "label": "Problema",
      "hint": "Explique el desafío al que se enfrenta. ¿Qué pasos ha tomado para tratar de resolver este desafío?"
    },
    "request": {
      "label": "Solicitud",
      "hint": "Explique lo que quiere discutir con este mentor y cuáles son sus expectativas para el período de mentoría."
    },
    "request_title_placeholder": "Solicitud de mentoría para mejorar el modelo de negocios.",
    "ventures_not_allowed": "Las empresas a continuación no coinciden con los requisitos de los mentores.",
    "nav_menu": {
      "agreement": "Acuerdo",
      "info": "Solicitar info",
      "feed": "Discusiones"
    }
  },
  "status_modal": {
    "success": {
      "mentor_profile": {
        "title": "Felicidades",
        "heading": "¡Tu perfil de mentor ha sido creado con éxito!",
        "content": "Tenga en cuenta que su perfil pasará por un proceso de verificación y moderación antes de que sea público y luego pueda comenzar a recibir solicitudes de los emprendedores.",
        "button": "Ver mi perfil"
      },
      "mentor_request": {
        "title": "Felicidades",
        "heading": "¡Su solicitud de mentor se ha creado con éxito!",
        "content": "Tenga en cuenta que su solicitud de mentor pasará por un proceso de revisión y moderación antes de ser enviada al mentor de su elección.",
        "button": "Ver mi solicitud de mentor"
      },
      "mentor_request_update": {
        "heading": "¡Su solicitud de mentor ha sido actualizada con éxito!"
      }
    }
  },
  "select_placeholder": "-- selecciona --",
  "select_placeholder_multiple": "-- seleccione --",
  "full_name": "Nombre completo",
  "email_address": "Correo electrónico",
  "city": "Ciudad",
  "country": "País",
  "about": "Acerca de",
  "linkedin_profile": "Perfil de LinkedIn",
  "see_example": "Vea el ejemplo",
  "submit": "Presentar",
  "settings": "Configuración",
  "sector": "Sector",
  "focus_country": "País de foco",
  "topic": "Tema",
  "interest": "Intereses",
  "venture_stage": "Fase de empresa",
  "options_selected": "opciones seleccionadas",
  "avatar": "Avatar",
  "spoken_language": "Lengua hablada",
  "geo_focus": "Enfoque geográfico",
  "mentor_type": "Tipo de mentor",
  "venture_type": "Fase de empresa",
  "get_started": "Registrarse",
  "vc4a_account": "Cuenta VC4A",
  "venture_profile": "Perfil de empresa",
  "add_venture": "Añadir empresa",
  "other": "Otros",
  "back": "Atrás",
  "venture": "Empresa",
  "mentor": "Mentor",
  "entrepreneur": "Empresario",
  "you": "Tú",
  "validation": {
    "required": "es necesario.",
    "url": "no es un URL válido",
    "invalid": "no es válido",
    "integer": "debe ser un entero",
    "between": "debe ser un valor entre",
    "too_short": "Demasiado corto.",
    "min_length": "debe contener al menos",
    "characters": "caracteres",
    "default_name": "Este campo"
  },
  "error_message": {
    "city": "Indicar tu ciudad es obligatorio",
    "country": "Indicar tu país es obligatorio",
    "linkedin_profile": "El campo de perfil de LinkedIn es obligatorio",
    "about": "La breve descripción de tu perfi es necesaria",
    "spoken_languages": "Es necesario que indique los idioma",
    "mentor_type": "El campo de tipo de mentor es obligatorio",
    "sector": "Debe seleccionar al menos un sector",
    "sectors": "El campo de sectores es obligatorio",
    "topic": "El campo de tema es obligatorio",
    "mentor_motivation": {
      "why": "El campo de motivación del mentor es obligatorio."
    },
    "mentor_request": {
      "alert_title": "Oops. Por favor, corrija los errores en el formulario.",
      "sign_in": "Inicia sesión o crea una cuenta en VC4A para continuar.",
      "no_venture_profile": "Se necesita un perfil de empresa para solicitar la mentoría.",
      "select_venture_profile": "Tienes que seleccionar una empresa para continuar.",
      "your_venture": "Su empresa",
      "venture_req": "debe tener una puntuación de 100%, por favor, actualice su empresa para continuar.",
      "update_venture": "Actualice su empresa"
    }
  },
  "single_request": {
    "back_btn": {
      "mentee": "Volver a la página de mentores",
      "mentor": "Volver a las solicitudes"
    },
    "alert": {
      "pending": {
        "title": "Revisión pendiente",
        "message": "Su solicitud se ha enviado para su revisión. Todavía puede editar su solicitud mientras está pendiente de revisión."
      },
      "approved": {
        "title": "Aprobado",
        "message": "Su solicitud ha sido aprobada por VC4A, y ahora está a la espera de una respuesta del mentor."
      },
      "accepted": {
        "title": "Aceptada",
        "message": "Su solicitud ha sido aceptada por el mentor."
      }
    },
    "request_content": "Contenido de solicitud",
    "introduction": "Introducción",
    "problem": "Problema",
    "request": "Solicitud",
    "topics": "Temas",
    "linked_venture": "Empresa asociada",
    "view_venture_profile": "Ver perfil de empresa",
    "view_account": "Vista de vista",
    "view_mentor_profile": "Ver perfil de mentor",
    "view_mentee_profile": "Ver perfil del empresario",
    "from": "De",
    "to": "A",
    "action_panel_hint": "Sólo usted puede ver esto",
    "modal": {
      "accept_request": {
        "action": "Aprobar solicitud",
        "confirmation": "¿Está seguro de que desea aceptar la solicitud?"
      },
      "reject_request": {
        "action": "Rechazar solicitud",
        "confirmation": "¿Está seguro de que desea rechazar la solicitud de {menteeName}?",
        "reason_legend": "Proporcionar feedback al alumno",
        "reason_text_placeholder": "Escriba aquí el motivo",
        "text_note": "Esta información se compartirá con el alumno",
        "no_time": "No tengo tiempo en este momento",
        "no_expertise": "Esto no entra dentro de mis conocimientos",
        "not_clear": "La solicitud de tutoría no está clara",
        "other": "Otra razón (especificar)"
      },
      "close_request": {
        "action": "Solicitud de cierre",
        "confirmation": "¿Está seguro de que desea cerrar la solicitud?"
      },
      "edit_request": {
        "action": "Solicitud de edición"
      },
      "private_note": {
        "action": "Añadir una nota privada",
        "placeholder": "Escribe aquí ...",
        "button_text": "Guardar nota"
      },
      "report": {
        "action": "Informe",
        "confirmation": "Ayúdenos a entender el problema. ¿Qué está pasando con esta petición?"
      }
    },
    "reason": "Motivo",
    "close_request": {
      "title": "¿Desea cerrar esta solicitud?",
      "action": "Sí, solicitud de cierre"
    },
    "mentorship_info": {
      "title": "Información sobre la mentoría"
    },
    "page_title": "Conexión de mentores",
    "page_hint": "Entre {mentor} (Mentor) y {mentee} (Mentee)",
    "manage_mentorship_btn": "Gestionar mentorización",
    "help_btn": "Ver ayuda",
    "discussions": "Discusiones",
    "help_modal": {
      "title": "Pautas para tu tutoría",
      "learn_more_btn_text": "Más información sobre las conexiones de tutoría"
    },
    "agreement_help_modal": {
      "title": "Pautas para tu acuerdo de tutoría",
      "learn_more_btn_text": "Más información sobre los acuerdos de mentorización"
    },
    "program": {
      "title": "Programa",
      "cta_text": "Ver página del programa"
    }
  },
  "status": {
    "status": "Estado",
    "pending_review": "Revisión pendiente",
    "review": "Revisión pendiente",
    "approved": "Aprobado",
    "accepted": "Aceptada",
    "rejected": "Declinado",
    "active": "Activo",
    "inactive": "Inactivo",
    "publish": "Publicado",
    "moderation": "Pendiente de revisión",
    "closed": "Cerrado",
    "pending_inactive": "Cerrado",
    "completed": "Completada",
    "unlisted": "Publicado (no listado)",
    "unavailable": "No disponible",
    "private": "Oculto",
    "denied": "Denegado",
    "draft_description": "Su solicitud está en borrador",
    "moderation_description": "Su solicitud ha sido revisada y requiere cambios.",
    "pending_review_description": "Su solicitud está pendiente de revisión",
    "pending_acceptance_description": "Su solicitud está pendiente de revisión",
    "pending_inactive_description": "Su solicitud ha sido cerrada por inactividad.",
    "inactive_description": "Su solicitud ha sido cerrada por inactividad.",
    "approved_description": "Esta conexión de mentoría está activa",
    "rejected_description": "Su solicitud ha sido rechazada",
    "closed_description": "Esta solicitud ha sido cerrada",
    "completed_description": "Esta solicitud ha sido cerrada"
  },
  "from": "de",
  "to": "a",
  "of": "de",
  "mentorship_feed": {
    "title": "Inicia la conversacion aquí.",
    "progress": "Progreso",
    "items_done": "Artículos hechos",
    "show_more_activity": "Cargar más",
    "no_activity": {
      "part_1": "Inicie una conversación.",
      "part_2": "Inicie la mentoría proponiendo una fecha/hora y un enlace/número de comunicación para reunirse por primera vez."
    },
    "comment_button": "Dejar un comentario"
  },
  "mentor_request_index": {
    "page_title": "Solicitudes de tutoría para usted",
    "page_subtitle": "Aquí encontrará las solicitudes que se le han enviado.",
    "navigation": {
      "pending": "Pendiente",
      "active": "Activo",
      "history": "Historia"
    },
    "empty_results": "No tiene ninguna solicitud",
    "request_count": "Tienes {count} solicitud de mentor | Tienes {count} solicitudes de mentores",
    "request_card": {
      "request_date": "Fecha de solicitud",
      "cta_text": "Ver la tutoría",
      "mentee_hint": "Alumno",
      "venture_profile_hint": "Perfil de la empresa vinculada"
    },
    "see_more_btn_text": "Ver más solicitudes"
  },
  "manage_profile": {
    "title": "Administra tu perfil",
    "hint": "Sólo tu puedes ver esto.",
    "moderation_comment": "Comentarios del equipo de VC4A",
    "resubmit_text": "Por favor, realice los ajustes necesarios a través del botón Editar perfil que aparece a continuación y vuelva a enviar su perfil guardando los cambios.",
    "status_description": {
      "publish": "Tu perfil ha sido aprobado por nuestro equipo y ya se encuentra listado",
      "moderation": "El equipo de VC4A tiene retroalimentación sobre tu perfil, revisa los comentarios y edita tu perfil.",
      "review": "Su perfil está actualmente a la espera de la revisión del moderador.",
      "unavailable": "Has configurado tu perfil para que no esté disponible, puedes cambiar la disponibilidad a través de la página de configuración.",
      "unlisted": "Su perfil está publicado y activo, pero actualmente no aparece públicamente.",
      "private": "Tu perfil ha sido configurado como privado y no es visible para otros usuarios."
    },
    "moderation_title": "Moderado",
    "moderation_approve": "Aprobar",
    "moderation_approve_unlisted": "Aprobar (no cotizan)",
    "moderation_changes": "Requerir cambios",
    "moderation_deny": "Denegar",
    "moderation_ban": "Prohibición",
    "requests_title": "Solicitudes",
    "pending_requests_unit": "{count} solicitud pendiente | {count} solicitudes pendientes",
    "matched_requests_unit": "{count} solicitud coincidente | {count} solicitudes aceptadas",
    "pending_requests_cta": "Tiene solicitudes pendientes. Haga clic para verlas.",
    "moderation_comment_title": "Comentarios de moderación",
    "moderation_comment_placeholder": "Introduzca aquí los cambios solicitados...",
    "moderation_deny_reason_placeholder": "Opcional: negar la razón..."
  },
  "actions": "Acciones",
  "profile_settings": {
    "page_subtitle": "Aquí están los ajustes para el perfil de tu mentor.",
    "mentorship_settings": "Configuración de mentoría",
    "availability_label": "¿Está disponible?",
    "max_connections_label": "Límite máximo de conexión",
    "email_notifications": "Correo electrónico",
    "update_notifications": "Actualizar las preferencias de las notificaciones",
    "allow_connection_label": "Permitir SOLO conexiones de emprendedores que:",
    "match_selected_ventures": "Coincidir con mi(s) etapa(s) de emprendimiento seleccionada(s)",
    "match_selected_sectors": "Coincidir con mi(s) sector(es) seleccionado(s)",
    "match_focus_locations": "Coincidir con mi(s) ubicación(es) de enfoque seleccionada(s)",
    "available" : "Actualmente estoy disponible para tomar conexiones",
    "unlisted_available" : "Estoy disponible para aceptar conexiones de cualquier persona en el Mercado de Mentoría (su perfil será revisado por los moderadores)",
    "unlisted" : "Actualmente sólo estoy disponible para programas específicos",
    "unavailable" : "Actualmente no estoy disponible",
    "private" : "Actualmente no estoy disponible y oculto mi perfil",
    "delete_profile_title": "Eliminar el perfil del mentor",
    "delete_profile_desc": "¿Desea eliminar su perfil de mentor? Esta es una acción permanente y no se puede revertir",
    "delete_profile_cta": "Elimina tu perfil de mentor",
    "delete_profile_modal": {
      "alert_message": "Si no lees esto, ocurrirán cosas malas inesperadas",
      "confirmation_text_1": "¿Estás seguro de que quieres eliminar tu perfil de mentor de VC4A?",
      "confirmation_text_2": "Esta es una acción permanente y no se puede deshacer. Esta acción borra:",
      "list_item_1": "toda la información del perfil",
      "list_item_2": "todas las solicitudes adjuntas a su perfil",
      "password_label": "Por favor, introduzca su contraseña para confirmar",
      "cta": "Eliminar este perfil de mentor",
    },
    "delete_profile_success": "Su perfil de mentor se ha eliminado con éxito.",
    "max_connections_placeholder": "Número entre 1 y 10"
  },
  "sort": {
    "date_asc": "Fecha (el más antiguo primero)",
    "date_desc": "Fecha (más reciente primero)",
    "name_asc": "Nombre (A-Z)",
    "name_desc": "Nombre (Z-A)"
  },
  "in": "En",
  "role": "Rol",
  "organization": "Organización",
  "feedback": "Observaciones",
  "review": "Revise",
  "close_request": {
    "page_title": "Solicitud de cierre",
    "feedback_question": "¿Cómo fue la mentoría?",
    "write_a_review": "Escribir una revisión",
    "rate": "Califica",
    "use_review": "Permítanos usar esta revisión en el perfil del mentor.",
    "as_mentor": {
      "review_hint": "Valora tu experiencia de tu mentee en esta solicitud."
    },
    "as_mentee": {
      "review_hint": "Valora tu experiencia de tu mentor en esta solicitud."
    }
  },
  "star_rating": {
    "1": "Pobre",
    "2": "Feria",
    "3": "Buena",
    "4": "Muy buena",
    "5": "Excelente",
    "default": "Toca una estrella para valorar"
  },
  "rating": "Calificación",
  "dismiss": "Desestimación",
  "activity": {
    "title": "Actividad"
  },
  "contact_form": {
    "title": "Contáctenos",
    "description": "Rellene el siguiente formulario y un representante de VC4A se pondrá en contacto con usted lo antes posible.",
    "send_message": "Enviar mensaje",
    "success_message": "Mensaje enviado satisfactoriamente",
    "message_label": "Mensaje"
  },
  "message": "Mensaje",
  "mentorship_agreement": {
    "page_title": "Acuerdo de mentoría",
    "start_date": "Fecha de inicio de mentoría",
    "duration": "Duración de la mentoría",
    "frequency": "Frecuencia de reunión",
    "frequency_meta": "{count} por {unit}",
    "challenges": "Desafíos",
    "challenges_identified": "Desafíos identificados",
    "closed_agreement_hint": "No se ha encontrado ningún acuerdo para esta conexión de tutoría",
    "add_challenge": "Añadir un nuevo reto",
    "goals": "Objetivos",
    "mentorship_goals": "Objetivos para la mentoría",
    "add_goal": "Añadir un nuevo objetivo",
    "submit_btn": "Acuerdo de presentación",
    "tab_title": "Acuerdo",
    "agreement_hint": "Se trata de un requisito tanto para el alumno como para el mentor. Ayuda a ambas partes a establecer un contrato definido.",
    "create_agreement": "Crear el acuerdo de mentoría",
    "create_agreement_btn": "Crear acuerdo",
    "help_btn": "¿Qué es esto?",
    "note_title": "Aviso",
    "edit_btn_text": "Editar el acuerdo",
    "back_btn_text": "Volver a Solicitud de Mentor"
  },
  "meeting_frequency": {
    "1": "Una reunión",
    "2": "Dos reuniones",
    "3": "Tres reuniones",
    "4": "Cuatro reuniones",
    "5": "Cinco reuniones",
    "6": "Seis reuniones",
    "7": "Siete reuniones",
    "8": "Ocho reuniones",
    "9": "Nueve reuniones"
  },
  "mentee": "Mentee",
  "moderator": "Moderador",
  "connection_details": {
    "title": "Detalles",
    "request_date": "Fecha de solicitud"
  },
  "select_all": "Seleccionar todo",
  "meta": {
    "deleted_user_name": "usuario eliminado",
  },
}
