import gql from 'graphql-tag';

// Get profile data
export const GET_MENTOR_DATA = gql`
query get_mentor_data($id: ID, $idType: String) {
    mentor(id: $id, idType: $idType) {
        id
        mentor_id
        author_email
        title
        post_content
        _content_es_ES
        _content_fr_FR
        slug
        avatar_url
        avatar_thumb_url
        organization
        role
        city
        linkedin_url
        duration
        why
        why_es_ES
        why_fr_FR
        permalink
        gender
    }
}
`;

export const GET_MENTORS = gql`
  query get_mentors($first: Int, $after: String, $last: Int, $before: String, $where: ConnectionMentorsWhereArgs) {
    mentors(first: $first, after: $after, last: $last, before: $before, where: $where) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
            resultTotal
            resultMaxPage
            resultCurPage
        }
        edges {
            cursor
            node {
                id
                mentor_id
                title
                excerpt
                avatar_thumb_url
                permalink
            }   
        }   
    }
}
`;

// Get profile data
export const GET_MENTOR_SETTINGS = gql`
  query get_mentor_data($id: ID, $idType: String) {
    mentor(id: $id, idType: $idType) {
        id
        mentor_id
        title
        status
        max_connections
        notifications_settings
        permalink
        match_venture_stage
        match_venture_sectors
        match_venture_focus_locations
    }
}
`;

// Create mentor.
export const CREATE_MENTOR_DATA = gql`
  mutation create_mentor_data($input: CreateMentorInput!) {
    createMentor(input: $input) {
        id
        mentor_id
        status
        post_content
        _content_es_ES
        _content_fr_FR
        slug
        avatar_url
        organization
        role
        city
        linkedin_url
        duration
        status
        why
        why_es_ES
        why_fr_FR
        gender
    }
}
`;

// Update mentor (persona) data.
export const UPDATE_MENTOR_DATA = gql`
  mutation update_mentor_data($input: UpdateMentorInput!) {
    updateMentor(input: $input) {
        id
        mentor_id
        status
        post_content
        _content_es_ES
        _content_fr_FR
        slug
        avatar_url
        organization
        role
        city
        linkedin_url
        duration
        status
        why
        why_es_ES
        why_fr_FR
        gender
    }
}
`;

// Update mentor avatar.
export const UPDATE_MENTOR_AVATAR = gql`
  mutation update_mentor_data($input: UpdateMentorAvatarInput!) {
    updateMentorAvatar(input: $input) {
        id
        avatar_url
    }
}
`;

// Retrieve mentors html
export const GET_MENTORS_AS_HTML = gql`
  mutation get_mentors_as_html($input: GetMentorsAsHtmlInput!) {
    getMentorsAsHtml(input: $input) {
        html
        pagination
    }
}
`;

// Retrieve mentors html
export const RESUBMIT_MENTOR_DATA = gql`
  mutation resubmit_mentor_data($input: ResubmitMentorDataInput!) {
    resubmitMentorData(input: $input) {
        mentor_id
    }
}
`;

// Update mentor settings.
export const UPDATE_MENTOR_SETTINGS = gql`
  mutation update_mentor_settings($input: UpdateMentorInput!) {
    updateMentor(input: $input) {
        id
        mentor_id
        status
        status
        max_connections
        notifications_settings
        match_venture_stage
        match_venture_sectors
        match_venture_focus_locations
    }
}
`;

export const TRASH_MENTOR = gql`
    mutation trash_mentor($input: TrashMentorInput!) {
        trashMentor(input: $input) {
            status
        }
    }
`;
